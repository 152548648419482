export const ANIMATION_DURATION = 0.7;

export const EXPANDED_CONTENT_WIDTH = 55.55;
export const EXPANDED_CONTENT_WIDTH_PERCENTAGE = `${EXPANDED_CONTENT_WIDTH}%`;
export const DEFAULT_WIDTH = '100%';

export const EXIT_STATE_SM = { height: 0, opacity: 0 };
export const EXIT_STATE_MD = {
  opacity: 0,
  height: '100%',
};

export const ANIMATE_STATE_SM = {
  opacity: 1,
  height: 'auto',
};
export const ANIMATE_STATE_MD = {
  opacity: 1,
  height: '100%',
};

export const EASE_IN_OUT = [0.76, 0, 0.24, 1];
