import cx from 'classnames';

import { Icon } from '~/v1/components/icons/icon';
import { IconType } from '~/v1/components/icons/icon.interfaces';

import styles from './trigger.module.scss';

interface TriggerProps {
  title: string;
  className?: string;
  onClick?: () => void;
  disabled: boolean;
}

export const AccordionTrigger: React.FC<TriggerProps> = ({
  title,
  className,
  onClick,
  disabled,
}) => {
  const classes = cx(styles.accordionTrigger, className);
  return (
    <button className={classes} onClick={onClick} disabled={disabled}>
      <div className={styles.iconWrapper}>
        <Icon type={IconType.Plus} className={cx(styles.icon)} />
      </div>
      <div className={styles.content}>
        <div className={cx('bodySmallTypography', styles.titleLabel)}>{title}</div>
      </div>
    </button>
  );
};
