import { useState } from 'react';

import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import { useBreakpoint } from '~/ui/components/grid/useBreakpoint';
import { Breakpoint } from '~/ui/styles/grid';
import {
  AccordionCard,
  type AccordionCardProps,
} from '~/v1/components/accordion/grantActivity/card/card';
import { AccordionTrigger } from '~/v1/components/accordion/grantActivity/trigger/trigger';
import { useThemeContext } from '~/v1/system/theme/theme.context';

import {
  ANIMATE_STATE_MD,
  ANIMATE_STATE_SM,
  ANIMATION_DURATION,
  DEFAULT_WIDTH,
  EASE_IN_OUT,
  EXIT_STATE_MD,
  EXIT_STATE_SM,
  EXPANDED_CONTENT_WIDTH,
  EXPANDED_CONTENT_WIDTH_PERCENTAGE,
} from './grantActivity.constants';
import styles from './grantActivity.module.scss';

export interface GrantActivityAccordionProps {
  accordionCards: AccordionCardProps[];
  className?: string;
}

export const GrantActivityAccordion: React.FC<GrantActivityAccordionProps> = ({
  accordionCards,
  className,
}) => {
  const breakpoint = useBreakpoint();
  const theme = useThemeContext();
  const themeMode = theme.copy === '#FFFFFF' ? 'dark' : 'light';
  const isSmallBreakpoint = breakpoint === Breakpoint.SM;
  const [disabledTrigger, setDisabledTrigger] = useState(false);

  const [expandedIndex, setExpandedIndex] = useState(0);

  const onActionClick = (index: number) => {
    setExpandedIndex(index);
    setDisabledTrigger(true);
  };

  const expandedItemWidth = isSmallBreakpoint ? DEFAULT_WIDTH : EXPANDED_CONTENT_WIDTH_PERCENTAGE;

  return (
    <div className={cx(styles.accordion, className)}>
      {!isSmallBreakpoint && (
        <div
          className={styles.placeholderCard}
          style={{
            width: expandedItemWidth,
          }}
        />
      )}

      <div className={styles.accordionInner}>
        {accordionCards.map((item, index) => {
          const isExpanded = expandedIndex === index;
          const itemWidth = isSmallBreakpoint
            ? DEFAULT_WIDTH
            : isExpanded
              ? EXPANDED_CONTENT_WIDTH_PERCENTAGE
              : `${(100 - EXPANDED_CONTENT_WIDTH) / (accordionCards.length - 1)}%`;

          return (
            <motion.div
              key={`${item.title}`}
              initial={{
                width: itemWidth,
              }}
              animate={{
                width: itemWidth,
              }}
              transition={{ duration: ANIMATION_DURATION, ease: EASE_IN_OUT }}
              className={cx(styles.accordionItem, styles[`accordionItem-${themeMode}`])}
            >
              <div className={cx(styles.colorUnderlay, styles[`colorUnderlay-${index}`])} />

              <AnimatePresence initial={false}>
                {!isExpanded && (
                  <motion.div
                    initial={isSmallBreakpoint ? EXIT_STATE_SM : EXIT_STATE_MD}
                    animate={isSmallBreakpoint ? ANIMATE_STATE_SM : ANIMATE_STATE_MD}
                    transition={{
                      duration: ANIMATION_DURATION,
                      ease: EASE_IN_OUT,
                    }}
                    exit={isSmallBreakpoint ? EXIT_STATE_SM : EXIT_STATE_MD}
                    className={styles.buttonWrapper}
                    style={{ width: itemWidth }}
                    onAnimationComplete={() => setDisabledTrigger(false)}
                  >
                    <AccordionTrigger
                      disabled={disabledTrigger}
                      onClick={() => onActionClick(index)}
                      title={item.title}
                    />
                  </motion.div>
                )}
              </AnimatePresence>

              <AnimatePresence initial={false}>
                {isExpanded && (
                  <motion.div
                    className={styles.contentWrapper}
                    style={{
                      width: expandedItemWidth,
                    }}
                    initial={isSmallBreakpoint ? EXIT_STATE_SM : EXIT_STATE_MD}
                    animate={isSmallBreakpoint ? ANIMATE_STATE_SM : ANIMATE_STATE_MD}
                    transition={{ duration: ANIMATION_DURATION, ease: EASE_IN_OUT }}
                    exit={isSmallBreakpoint ? EXIT_STATE_SM : EXIT_STATE_MD}
                  >
                    <AccordionCard {...item} />
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};
