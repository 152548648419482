import { type GrantActivityArticle } from '~/v1/_types/GrantActivityArticle';
import { type NestedGrantActivityCard } from '~/v1/_types/NestedGrantActivityCard';
import { type Spotlight_SpotlightVideo } from '~/v1/_types/Spotlight';
import { ContentType } from '~/v1/constants/contentType';
import { Route } from '~/v1/constants/route';

import { type AccordionCardProps } from './card';

export const mapAccordionCards = (
  cards: GrantActivityArticle[] | NestedGrantActivityCard[],
): AccordionCardProps[] => {
  return cards.map(card => {
    const spotlight = card.body.includedEntries.find(
      ({ data }) => data?.__typename === ContentType.SPOTLIGHT_VIDEO,
    )?.data as Spotlight_SpotlightVideo;

    return {
      title: card.title,
      image: card.leadImage,
      videoUrl: spotlight?.video?.src ?? spotlight?.videoUrl ?? undefined,
      eyebrow: card.grant.granteeName,
      href: `${Route.GRANT_STORY}/${card.slug}`,
    };
  });
};
